@mixin gridStyling {
	display: grid;
	gap: 1.1rem;

	@media screen and (max-width: 680px) {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
	@media screen and (min-width: 680px) and (max-width: 768px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
	@media screen and (min-width: 768px) and (max-width: 1000px) {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
	@media screen and (min-width: 1000px) and (max-width: 1320px) {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
	@media screen and (min-width: 1320px) and (max-width: 1700px) {
		grid-template-columns: repeat(5, minmax(0, 1fr));
	}
	@media screen and (min-width: 1700px) {
		grid-template-columns: repeat(6, minmax(0, 1fr));
	}
}

.china-press-dialog-content::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 7px;
}

.china-press-dialog-content::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0, 0, 0, .5);
	-webkit-box-shadow: 0 0 1px rgba(0,0,0,.5);
}